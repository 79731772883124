import React, { FC } from "react";

import Button from "../../Button/Button";

import { DangerSignIcon } from "../../Icons/icons";

import analytics from "../../../../utilities/analytics";

interface ReportViolationButtonProps {
  reportId: string;
  plateNumber: string;
}

const ReportViolationButton: FC<ReportViolationButtonProps> = ({
  reportId,
  plateNumber,
}) => {
  const tallyPopupHandler = () => {
    analytics.reportViolationClick();
    window.Tally.openPopup("wzNJK1", {
      hiddenFields: {
        reportId: reportId,
        plateNumber: plateNumber,
      },
    });
  };

  return (
    <Button
      variant="tertiaryCard"
      onClick={tallyPopupHandler}
      icon={<DangerSignIcon />}
    >
      Prijavi zloupotrebu
    </Button>
  );
};

export default ReportViolationButton;
