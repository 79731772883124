import { FC, useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import Carousel from "../../Carousel/Carousel";
import { Report } from "../../../../types/reports/report";
import styles from "./CardImage.module.scss";

interface CardImageProps {
  papak: Report;
}

const CardImage: FC<CardImageProps> = ({ papak }) => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  //const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const openLightbox = (index: number) => {
    // setSelectedImageIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  return (
    <>
      <div className={styles.container} data-testid="carousel">
        {papak.thumbnailurl.length > 1 && (
          <Carousel>
            {papak.thumbnailurl.map((imgUrl, index) => (
              <img
                key={imgUrl}
                className={styles.image}
                src={imgUrl || papak.url[0]}
                alt={papak.plate}
                width={270}
                height={212}
                onClick={() => openLightbox(index)}
              />
            ))}
          </Carousel>
        )}
        {papak.thumbnailurl.length === 1 && (
          <img
            className={styles.image}
            src={papak.thumbnailurl[0] || papak.url[0]}
            alt={papak.plate}
            width={270}
            height={212}
            onClick={() => openLightbox(0)}
          />
        )}
      </div>
      <Lightbox
        open={lightboxOpen}
        close={closeLightbox}
        slides={papak.url.map((imgUrl) => ({ src: imgUrl }))}
      />
    </>
  );
};
export default CardImage;
