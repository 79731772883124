import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Heading from "../../shared/Heading/Heading";
import Button from "../../shared/Button/Button";

import SectionCarousel from "../../shared/SectionCarousel/SectionCarousel";

import { ReportResponse } from "../../../types/reports/report";

import { getLatestPapci } from "../../../pages/HomePage/api";

import styles from "./PosljednjiPapciSection.module.scss";

const PosljednjiPapciSection: FC = () => {
  const [posljednjiPapci, setPosljednjiPapci] = useState<ReportResponse>();

  useEffect(() => {
    getLatestPapci(1, 8).then((res) => setPosljednjiPapci(res));
  }, []);

  const navigate = useNavigate();

  const goToAllPapciHandler = () => {
    navigate("/posljednji-papci");
    window.scroll({ top: 0, behavior: "smooth" });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <Heading variant="h1" className={styles.heading}>
          Posljednji Papci
        </Heading>
        {posljednjiPapci && (
          <SectionCarousel reports={posljednjiPapci?.items} />
        )}
        <div className={styles.seeAllButton}>
          <Button variant="secondary" onClick={goToAllPapciHandler}>
            Pogledaj sve
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PosljednjiPapciSection;
