import { FC, ReactNode, useEffect, useState } from "react";

import styles from "./Carousel.module.scss";
import React from "react";

import { LeftArrowIcon, RightArrowIcon } from "../Icons/icons";

interface CarouselProps {
  children: ReactNode;
}

const Carousel: FC<CarouselProps> = ({ children }) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [length, setLength] = useState<number>(React.Children.count(children));

  useEffect(() => {
    setLength(React.Children.count(children));
  }, [children]);

  const nextImage = (): void => {
    if (currentIndex < length - 1) {
      setCurrentIndex((prevState) => prevState + 1);
    } else {
      setCurrentIndex(0);
    }
  };

  const prevImage = (): void => {
    if (currentIndex > 0) {
      setCurrentIndex((prevState) => prevState - 1);
    } else {
      setCurrentIndex(length - 1);
    }
  };

  return (
    <div className={styles.container} data-testid="carousel-container">
      <div className={styles.wrapper}>
        <button
          className={styles.leftArrow}
          onClick={prevImage}
          data-testid="left-arrow"
        >
          <div className={styles.icon}>
            <LeftArrowIcon />
          </div>
        </button>
        <div className={styles.contentWrapper}>
          <div
            className={styles.content}
            style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          >
            {children}
          </div>
        </div>
        <button
          className={styles.rightArrow}
          onClick={nextImage}
          data-testid="right-arrow"
        >
          <div className={styles.icon}>
            <RightArrowIcon />
          </div>
        </button>
      </div>
    </div>
  );
};

export default Carousel;
