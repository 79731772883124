import { FC, useEffect, useState } from "react";

import { BackToTopIcon } from "../Icons/icons";

import styles from "./BackToTop.module.scss";

const BackToTop: FC = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      const revealPosition = window.innerWidth > 480 ? 800 : 1000;
      setIsVisible(window.scrollY > revealPosition);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const goToTopHandler = () => {
    window.scroll({ top: 0, behavior: "smooth" });
  };

  return (
    <div
      onClick={goToTopHandler}
      className={`${styles.top} ${isVisible ? styles.visible : ""}`}
    >
      <BackToTopIcon />
    </div>
  );
};

export default BackToTop;
