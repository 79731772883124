import React, { FC } from "react";

import styles from "./SocialMediaLinks.module.scss";
import { FacebookIcon, InstagramIcon, TwitterIcon } from "../../../Icons/icons";

const SocialMediaLinks: FC = () => {
  return (
    <div className={styles.container}>
      <a
        href="https://www.facebook.com/patrola.ba"
        target="_blank"
        rel="noreferrer"
        data-testid="facebook-link"
      >
        <FacebookIcon />
      </a>
      <a
        href="https://twitter.com/patrolaba"
        target="_blank"
        rel="noreferrer"
        data-testid="twitter-link"
      >
        <TwitterIcon />
      </a>
      <a
        href="https://www.instagram.com/patrola.ba/"
        target="_blank"
        rel="noreferrer"
        data-testid="instagram-link"
      >
        <InstagramIcon />
      </a>
    </div>
  );
};

export default SocialMediaLinks;
