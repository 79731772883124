import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";

import analytics from "./utilities/analytics";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "yet-another-react-lightbox/styles.css";

analytics.initialize();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <ToastContainer />
    <App />
  </React.StrictMode>,
);
