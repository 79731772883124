import { FC } from "react";

import { Report } from "../../../../types/reports/report";

import styles from "./LicensePlate.module.scss";
import Typography from "../../Typography/Typography";

interface CardImageProps {
  papak: Report;
}

const LicensePlate: FC<CardImageProps> = ({ papak }) => {
  const formatLicensePlate = (plate: string | undefined) => {
    if (!plate) return "";

    const formattedPlate = plate
      .toUpperCase()
      .replace(/(\w{3})(\w)(\w{3})/, "$1-$2-$3");
    return formattedPlate;
  };

  return (
    <div className={styles.container} data-testid="license-plate-container">
      <div className={styles.side}>
        <p>BIH</p>
      </div>
      <div className={styles.content}>
        <Typography>{formatLicensePlate(papak.plate)}</Typography>
      </div>
    </div>
  );
};

export default LicensePlate;
