import { Dispatch, FC, SetStateAction, useEffect } from "react";

import DotIndicator from "./DotIndicator/DotIndicator";
import SectionCarousel from "../SectionCarousel/SectionCarousel";
import { LeftSliderIcon, RightSliderIcon } from "../Icons/icons";

import { ReportResponse, Report } from "../../../types/reports/report";

import styles from "./Pagination.module.scss";

interface PaginationProps {
  response: ReportResponse;
  setPage: Dispatch<SetStateAction<number>>;
  currentPage: number;
  plate: string;
  onMorePapci?: () => Promise<Array<Report>>;
  variant?: "primary" | "secondary" | "tertiary";
}

const Pagination: FC<PaginationProps> = ({
  response,
  setPage,
  currentPage,
  variant,
  plate,
  onMorePapci,
}) => {
  const previousPage = () => {
    if (response.hasPreviousPage === true) setPage(currentPage - 1);
  };

  const nextPage = () => {
    if (response.hasNextPage === true) setPage(currentPage + 1);
  };

  useEffect(() => {
    setPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plate]);

  const iteratePages = () => {
    const dotIndicators = [];
    for (let i = 1; i <= response.totalPages; i++) {
      dotIndicators.push(
        <DotIndicator
          key={i}
          page={i}
          currentPage={currentPage}
          setPage={setPage}
          variant={variant}
        />,
      );
    }
    return dotIndicators;
  };

  return (
    <div className={styles.container}>
      <SectionCarousel reports={response.items} onMorePapci={onMorePapci} />
      <div className={styles.navigationContainer}>
        <div className={styles.arrowIcon} onClick={previousPage}>
          <LeftSliderIcon />
        </div>
        <div className={styles.dotIndication}>{iteratePages()}</div>
        <div className={styles.arrowIcon} onClick={nextPage}>
          <RightSliderIcon />
        </div>
      </div>
    </div>
  );
};

export default Pagination;
