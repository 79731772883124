import { FC, useEffect, useRef, useState } from "react";

import Card from "../Card/Card";
import { LeftSliderIcon, RightSliderIcon } from "../Icons/icons";

import { Report } from "../../../types/reports/report";

import styles from "./SectionCarousel.module.scss";

interface SectionCarouselProps {
  reports: Array<Report>;
  onMorePapci?: () => Promise<Array<Report>>;
}

const SectionCarousel: FC<SectionCarouselProps> = ({
  reports,
  onMorePapci,
}) => {
  const [papci, setPapci] = useState<Array<Report>>(reports);
  const [currentIndex, setCurrentIndex] = useState(0);

  const prevItem = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevState) => prevState - 1);
    }
  };

  const nextItem = async () => {
    if (currentIndex < (papci ? papci.length - 1 : 0)) {
      setCurrentIndex((prevState) => prevState + 1);
    }

    if (currentIndex === papci.length - 1 && onMorePapci) {
      try {
        const additionalPapci = (await onMorePapci()) || [];
        setPapci((prevPapci) => [...prevPapci, ...additionalPapci]);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (carousel !== null && carousel.current !== null) {
      carousel.current.scrollLeft = currentIndex * (272 + 24);
    }
  }, [currentIndex]);

  const carousel = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (carousel !== null && carousel.current !== null) {
      carousel.current.scrollLeft = currentIndex * (272 + 24);
    }
  }, [currentIndex]);

  useEffect(() => {
    setPapci(reports);
    setCurrentIndex(0);
  }, [reports]);

  return (
    <>
      <div className={styles.wrapper} ref={carousel}>
        {papci.map((papak) => (
          <Card key={papak.id} papak={papak} />
        ))}
      </div>
      <div className={styles.slider}>
        <button onClick={prevItem}>
          <LeftSliderIcon />
        </button>
        <button onClick={nextItem}>
          <RightSliderIcon />
        </button>
      </div>
    </>
  );
};

export default SectionCarousel;
