import { FC, useState, useEffect } from "react";

import Button from "../../Button/Button";

import { ThumbDownIcon, ThumbUpIcon } from "../../Icons/icons";

import { downVotePapak, upVotePapak } from "../../../../pages/HomePage/api";

import analytics from "../../../../utilities/analytics";

import { Report } from "../../../../types/reports/report";

import styles from "./VoteButton.module.scss";

interface VoteButtonProps {
  variant?: "upVote" | "downVote";
  papak: Report;
  upVotes?: number | null;
  downVotes?: number | null;
  onUpVote?: () => void;
  onDownVote?: () => void;
}

const VoteButton: FC<VoteButtonProps> = ({
  variant,
  papak,
  upVotes: initialUpVotes,
  downVotes: initialDownVotes,
  onUpVote,
  onDownVote,
}) => {
  const [upVotes, setUpVotes] = useState<number | null>(initialUpVotes ?? null);
  const [downVotes, setDownVotes] = useState<number | null>(
    initialDownVotes ?? null,
  );

  useEffect(() => {
    setUpVotes(initialUpVotes ?? null);
    setDownVotes(initialDownVotes ?? null);
  }, [initialUpVotes, initialDownVotes]);

  const upVote = () => {
    onUpVote &&
      upVotePapak(papak.id).then(() => {
        onUpVote();
        setUpVotes((prevVotes) => (prevVotes !== null ? prevVotes + 1 : 1));
      });
    analytics.voteUpClick();
  };

  const downVote = () => {
    onDownVote &&
      downVotePapak(papak.id).then(() => {
        onDownVote();
        setDownVotes((prevVotes) => (prevVotes !== null ? prevVotes + 1 : 1));
      });
    analytics.voteDownClick();
  };

  const renderContent = () => {
    if (variant === "upVote") {
      return (
        <Button onClick={upVote} variant="primaryCard" icon={<ThumbUpIcon />}>
          Pravi papak
        </Button>
      );
    } else
      return (
        <Button onClick={downVote} variant="primaryCard" icon={<ThumbDownIcon />}>
          Nije papak
        </Button>
      );
  };

  const renderVotes = () => {
    if (variant === "upVote") {
      if (!papak.papak) {
        return "0";
      }
      return upVotes;
    }

    if (variant === "downVote") {
      if (!papak.notPapak) {
        return "0";
      }
      return downVotes;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <p
          className={
            variant === "upVote" ? `${styles.upVote}` : `${styles.downVote}`
          }
        >
          {renderVotes()}
        </p>
      </div>
      {renderContent()}
    </div>
  );
};

export default VoteButton;
