import { FC, useState } from "react";

import LicensePlate from "./LicensePlate/LicensePlate";
import ReportViolationButton from "./ReportViolationButton/ReportViolationButton";
import CardImage from "./CardImage/CardImage";
import VoteButton from "./VoteButton/VoteButton";
import Typography from "../Typography/Typography";
import { CalendarIcon, ClockIcon, LocationIcon } from "../Icons/icons";
import AllReportsButton from "./ViewAllReportsButton/AllReportsButton";
import { Report } from "../../../types/reports/report";
import styles from "./Card.module.scss";

interface CardProps {
  papak: Report;
  variant?: "default" | "allReports";
}

const Card: FC<CardProps> = ({ papak, variant = "default" }) => {
  const [upVotes, setUpVotes] = useState<number | null>(papak.papak);
  const [downVotes, setDownVotes] = useState<number | null>(papak.notPapak);

  const isDefaultVariant = variant === "default";
  const reportDate = new Date(papak.date);

  const formatDate = (date: Date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${day}.${month}.${year}.`;
  };

  return (
    <div className={styles.container} data-testid="card-container">
      <div className={styles.heading}>
        <CardImage papak={papak} />
        <LicensePlate papak={papak} />
      </div>
      <div className={styles.content}>
        <div className={styles.location}>
          <div className={styles.paragraph}>
            <div className={styles.icon}>
              <LocationIcon />
            </div>
            <a
              href={`http://maps.google.com/?q=${papak.address}`}
              target="_blank"
              rel="noreferrer"
            >
              <Typography className={styles.text}>
                {papak.address.replace(", Bosnia and Herzegovina", "")}
              </Typography>
            </a>
          </div>
        </div>
        <div className={styles.dateTime}>
          <div
            className={`${styles.paragraph} ${styles.date}`}
            data-testid="date"
          >
            <div className={styles.icon}>
              <CalendarIcon />
            </div>
            <Typography className={styles.text}>
              {formatDate(reportDate)}
            </Typography>
          </div>
          <div className={styles.paragraph} data-testid="time">
            <div className={styles.icon}>
              <ClockIcon />
            </div>
            <Typography
              className={styles.text}
            >{`${reportDate.getUTCHours()}:${reportDate
                .getUTCMinutes()
                .toString()
                .padStart(2, "0")}`}</Typography>
          </div>
        </div>
      </div>
      {isDefaultVariant && (
        <div className={styles.voting}>
          <VoteButton
            variant="upVote"
            papak={papak}
            upVotes={upVotes}
            onUpVote={() => setUpVotes((prevUpVotes) => (prevUpVotes || 0) + 1)}
          />
          <VoteButton
            variant="downVote"
            papak={papak}
            downVotes={downVotes}
            onDownVote={() =>
              setDownVotes((prevDownVotes) => (prevDownVotes || 0) + 1)
            }
          />
        </div>
      )}
      {!isDefaultVariant && (
        <div className={styles.allReports}>
          <AllReportsButton />
        </div>
      )}
      <div className={styles.reportViolationButton}>
        <ReportViolationButton reportId={papak.id} plateNumber={papak.plate} />
      </div>
    </div>
  );
};

export default Card;
