import React, { FC, ReactNode } from "react";

import styles from "./Button.module.scss";
import Typography from "../Typography/Typography";

interface ButtonProps {
  children: ReactNode;
  onClick: () => void;
  icon?: ReactNode;
  variant?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "primaryCard"
    | "tertiaryCard";
  className?: string;
}

const Button: FC<ButtonProps> = ({
  onClick,
  variant,
  icon,
  children,
  className,
}) => {
  const variantChecker =
    ((variant === "primary" || !variant) && styles.primary) ||
    (variant === "secondary" && styles.secondary) ||
    (variant === "tertiary" && styles.tertiary) ||
    (variant === "primaryCard" && styles.primaryCard) ||
    (variant === "tertiaryCard" && styles.tertiaryCard);

  return (
    <button
      onClick={onClick}
      className={`${styles.button} ${variantChecker} ${className || ""}`}
      data-testid="custom-button"
    >
      {icon && <div className={styles.icon}>{icon}</div>}
      <Typography
        className={`${
          (variant === "primary" && styles.inverseTextColor) ||
          (variant === "primaryCard" && styles.cardButtonText)
        }`}
      >
        {children}
      </Typography>
    </button>
  );
};

export default Button;
