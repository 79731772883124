import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Heading from "../../shared/Heading/Heading";
import Button from "../../shared/Button/Button";

import SectionCarouselDesktop from "../../shared/SectionCarouselDesktop/SectionCarouselDesktop";

import { NajpopularnijiPapciReport } from "../../../types/reports/report";

import { getNajpopularnijiPapci } from "../../../pages/HomePage/api";

import styles from "./NajpopularnijiPapciSection.module.scss";

const NajpopularnijiPapciSection: FC = () => {
  const [najpopularnijiPapci, setNajpopularnijiPapci] = useState<
    NajpopularnijiPapciReport[]
  >([]);

  useEffect(() => {
    getNajpopularnijiPapci().then((res) => {
      setNajpopularnijiPapci(res);
    });
  }, []);

  const navigate = useNavigate();

  const goToAllPapciHandler = () => {
    navigate("/najpopularniji-papci");
    window.scroll({ top: 0, behavior: "smooth" });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <Heading variant="h1" className={styles.heading}>
          Najpopularniji Papci
        </Heading>
        {<SectionCarouselDesktop reports={najpopularnijiPapci.slice(0, 20)} />}
        {/*  <div className={styles.seeAllButton}>
          <Button variant="secondary" onClick={goToAllPapciHandler}>
            Pogledaj sve
          </Button>
        </div> */}
      </div>
    </div>
  );
};

export default NajpopularnijiPapciSection;
