import React from "react";

import Button from "../Button/Button";
import { PatrolaIcon } from "../Icons/icons";

import styles from "./Header.module.scss";

const Header = () => {
  const navigateHandler = () => {
    window.location.href = "https://app.patrola.ba/onboarding";
  };

  return (
    <div className={styles.header}>
      <div className={styles.logo} onClick={() => window.location.href = "https://www.parkingpapak.ba"}>
        {<PatrolaIcon />}
      </div>
      <div className={styles.wallOfShame} onClick={() => window.location.href = "https://www.parkingpapak.ba"}>
        ParkingPapak
      </div>
      <div className={styles.headerButton}>
        <div className={styles.desktopButton}>
          <Button onClick={navigateHandler} variant="primary">
            Prijavi Parking Papka
          </Button>
        </div>
        <div className={styles.mobileButton}>
          <Button onClick={navigateHandler} variant="primary">
            Prijavi
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Header;
