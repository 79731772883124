import { FC, useEffect, useState } from "react";

import SearchBar from "../SearchBar/SearchBar";
import Button from "../Button/Button";
import TotalReportCount from "../TotalReports/TotalReportCount"; // Import the TotalReportCount component

import styles from "./HeroSection.module.scss";
import { NoParkingSign, YellowCarIcon } from "../Icons/icons";
import { getReportsBySearch } from "../../../pages/HomePage/api";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import Pagination from "../Pagination/Pagination";
import { ReportResponse } from "../../../types/reports/report";
import Heading from "../Heading/Heading";

const HeroSection: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [response, setResponse] = useState<ReportResponse>({
    hasNextPage: false,
    hasPreviousPage: false,
    items: [],
    pageNumber: 0,
    totalCount: 0,
    totalPages: 0,
  });
  const [plate, setPlate] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const fetchReportsBySearch = (plateNumber: string) => {
    setPlate(plateNumber);
    getReportsBySearch(pageNumber, 3, plateNumber)
      .then((data) => {
        setResponse(data);
        setTotalCount(data.totalCount);
        setIsLoading(false);
      })
      .catch((error) => console.log(error));
  };

  const searchHandler = () => {
    setIsClicked(true);
    setIsLoading(true);
  };

  const morePapciHandler = async () => {
    try {
      const nextPageNumber = pageNumber + 1;
      const data = await getReportsBySearch(nextPageNumber, 3, plate);
      setPageNumber(nextPageNumber);
      return data.items;
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const renderContent = () => {
    if (plate === "") {
      return;
    }
    if (isLoading === true) {
      return (
        <div className={styles.searchContainer}>
          <LoadingSpinner />
        </div>
      );
    }
    if (totalCount === 0) {
      return (
        <div className={styles.searchContainer}>
          <p className={styles.searchText}>
            Trenutno nema prijava za unesenu registracijsku oznaku.
          </p>
          <h3 className={styles.searchHeading}>
            Čestitamo! Vi niste parking papak.
          </h3>
        </div>
      );
    } else
      return (
        <div className={styles.searchContainer}>
          <p className={styles.searchText}>
            Registarske oznake <span>{plate}</span> su prijavljene{" "}
            <span>{totalCount}</span> {totalCount === 1 ? "put" : "puta"}
          </p>
          <div>
            <Pagination
              response={response}
              setPage={setPageNumber}
              currentPage={pageNumber}
              onMorePapci={morePapciHandler}
              variant="primary"
              plate={plate}
            />
          </div>
        </div>
      );
  };

  const navigateHandler = () => {
    window.location.href = "https://app.patrola.ba/onboarding";
  };

  useEffect(() => {
    fetchReportsBySearch(plate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plate, pageNumber]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={`${styles.top} ${isClicked ? styles.expand : ""}`}>
          <Heading>Da li ste Vi Parking Papak?</Heading>
          <div className={styles.topImage}>
            <NoParkingSign />
          </div>
          <div className={styles.topSearch}>
            <SearchBar
              fetchData={fetchReportsBySearch}
              onSearch={searchHandler}
            />
            {isClicked && renderContent()}
          </div>
        </div>
        <div className={styles.bottom}>
          <div className={styles.bottomContent}>
            <div className={styles.buttonContainer}></div>
            <p>
              Ne dopustite da nepropisno parkiranje postane norma u vašem
              susjedstvu. Kako biste aktivno sudjelovali u očuvanju reda i
              sigurnosti, pozivamo vas da podignete svoj glas i prijavite
              prekršitelje parkinga. <br></br>
              <br></br>Zajedničkim naporima možemo stvoriti okolinu u kojoj se
              poštuju pravila, a nepropisno parkiranje postaje iznimka, ne
              pravilo. Prijavite prekršitelje danas i budite dio pozitivne
              promjene u vašem susjedstvu.
            </p>
            <div className={styles.buttonContainer}>
              <Button
                onClick={navigateHandler}
                variant="primary"
                className={styles.reportPapakButton}
              >
                Prijavi Parking Papka
              </Button>
            </div>
          </div>
          <div className={styles.bottomImage}>
            <YellowCarIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
