import { FC, ReactNode } from "react";

import styles from "./Heading.module.scss";

interface HeadingProps {
  variant?: "h1" | "h2" | "h3";
  color?: "default" | "inverse";
  className?: string;
  children: ReactNode;
}

const Heading: FC<HeadingProps> = ({ variant, className, children }) => {
  const variantChecker = () => {
    switch (variant) {
    case "h1":
      return styles.h1;
    case "h2":
      return styles.h2;
    case "h3":
      return styles.h3;
    default:
      return styles.h1;
    }
  };

  const TagVariant = variant || "h1";

  return (
    <TagVariant className={`${styles.common} ${variantChecker} ${className}`}>
      {children}
    </TagVariant>
  );
};

export default Heading;
