import React, { FC } from "react";
import Button from "../../Button/Button";
import Typography from "../../Typography/Typography";
import styles from "./AllReportsButton.module.scss";

const AllReportsButton: FC = () => {
  const handleClick = () => {
    //     intentionally left blank
  };

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <Typography className={styles.NumberOfReports}>15 prijava</Typography>
      </div>
      <div className={styles.ViewAllReports}>
        <Button
          onClick={handleClick}
          variant="primaryCard"
          className={styles.primaryCard2}
        >
          Pogledaj sve prijave
        </Button>
      </div>
    </div>
  );
};

export default AllReportsButton;
