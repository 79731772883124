import React from "react";
import Button from "../../Button/Button";
import { BackArrowIcon, FrontArrowIcon } from "../../Icons/icons";
import styles from "./Pagination2.module.scss";

interface PaginationProps {
  totalPages: number;
  itemsPerPage: number;
  currentPage: number;
  onPageChange: (pageNumber: number) => void;
  isMobile: boolean;
}

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
  isMobile,
}) => {
  const maxVisiblePages = 9;

  const handlePageChange = (pageNumber: number) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      onPageChange(pageNumber);
    }
  };

  const renderPaginationButton = (page: number) => {
    if (isMobile) {
      return null;
    }

    return (
      <button
        key={page}
        className={`${styles.pageButton} ${
          page === currentPage ? styles.active : ""
        }`}
        onClick={() => handlePageChange(page)}
      >
        {page}
      </button>
    );
  };

  const calculateStartPage = () => {
    if (currentPage <= 1) {
      return 1;
    } else if (currentPage + maxVisiblePages > totalPages) {
      return Math.max(totalPages - maxVisiblePages + 1, 1);
    } else {
      return currentPage;
    }
  };

  const startPage = calculateStartPage();
  const endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);

  const pages = Array.from(
    { length: endPage - startPage + 1 },
    (_, index) => startPage + index,
  );

  return (
    <div className={styles.pagination}>
      {currentPage > 1 && !isMobile && (
        <button
          className={styles.arrowButton}
          onClick={() => handlePageChange(currentPage - 1)}
        >
          <BackArrowIcon />
        </button>
      )}

      {isMobile ? (
        <Button
          variant="secondary"
          className={styles.loadMoreButton}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          Učitaj više
        </Button>
      ) : (
        pages.map((page) => renderPaginationButton(page))
      )}

      {currentPage < totalPages && !isMobile && (
        <button
          className={styles.arrowButton}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          <FrontArrowIcon />
        </button>
      )}
    </div>
  );
};

export default Pagination;
