import Layout from "../../components/shared/Layout/Layout";
import HeroSection from "../../components/shared/HeroSection/HeroSection";
import PosljednjiPapciSection from "../../components/home/PosljednjiPapciSection/PosljednjiPapciSection";
import NajpopularnijiPapciSection from "../../components/home/NajpopularnijiPapciSection/NajpopularnijiPapciSection";

const HomePage = () => {
  return (
    <Layout>
      <HeroSection />
      <PosljednjiPapciSection />
      <NajpopularnijiPapciSection />
    </Layout>
  );
};

export default HomePage;
