import React, { FC } from "react";

import SocialMediaLinks from "./SocialMediaLinks/SocialMediaLinks";

import styles from "./FooterBottomBar.module.scss";
import Typography from "../../Typography/Typography";

const FooterBottomBar: FC = () => {
  return (
    <div className={styles.container} data-testid="footer-bottom-bar-container">
      <div className={styles.text}>
        <Typography className={styles.darkerTextColor}>
          Sva prava pridržana. Origin d.o.o.
        </Typography>
      </div>
      <SocialMediaLinks />
      <div className={styles.text}>
        <Typography className={styles.darkerTextColor}>Patrola.ba</Typography>
      </div>
    </div>
  );
};

export default FooterBottomBar;
