// PosljednjiPapci.jsx
import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getLatestPapci } from "../HomePage/api";
import Card from "../../components/shared/Card/Card";
import { BackArrowIcon } from "../../components/shared/Icons/icons";
import Layout from "../../components/shared/Layout/Layout";
import { Report, ReportResponse } from "../../types/reports/report";
import styles from "./PosljednjiPapci.module.scss";
import Pagination2 from "../../components/shared/Pagination/Pagination2/Pagination2";
import Button from "../../components/shared/Button/Button";

const PosljednjiPapci: FC = () => {
  const [papkeArray, setPapkeArray] = useState<Array<Report>>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 16;
  const [totalCount, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    fetchPapkeArray(currentPage);
  }, [currentPage]);

  const fetchPapkeArray = async (page: number) => {
    try {
      const response: ReportResponse = await getLatestPapci(page, itemsPerPage);
      const { items, totalCount, totalPages } = response;
      setPapkeArray(items);
      setTotalCount(totalCount);
      setTotalPages(totalPages);
    } catch (error) {
      console.error("Error fetching Papke array:", error);
      setPapkeArray([]);
      setTotalCount(0);
      setTotalPages(0);
    }
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    const handleResize = () => {
      window.innerWidth <= 1024
        ? setIsMobileView(true)
        : setIsMobileView(false);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleLoadMoreClick = () => {
    setCurrentPage(currentPage + 1);
  };

  return (
    <Layout>
      <div>
        <Link to="/" className={styles.backLink}>
          <div className={styles.backArrow}>
            <BackArrowIcon />
          </div>
          <span>Nazad na Početnu stranicu</span>
        </Link>
      </div>
      <div className={styles.latestPapci}>Posljednji Papci</div>
      <div className={styles.totalApplications}>
        Ukupno {totalCount} prijava
      </div>
      <div className={styles.cardContainer}>
        <div data-testid="card-container" className={styles.cards}>
          {papkeArray.map((papak: Report) => (
            <Card key={papak.id} papak={papak} />
          ))}
        </div>
      </div>
      {isMobileView ? (
        <div className={styles.loadMore}>
          <Button
            variant="secondary"
            className={styles.loadMoreButton}
            onClick={handleLoadMoreClick}
          >
            Učitaj više
          </Button>
        </div>
      ) : (
        // Show pagination for desktop
        <Pagination2
          totalPages={totalPages}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          isMobile={false}
        />
      )}
    </Layout>
  );
};

export default PosljednjiPapci;
