import ReactGA from "react-ga4";

import { envConfig } from "./envConfig";

const initialize = () => {
  if (!envConfig.analyticsKey) {
    return console.log("Analytics Mess ID missing");
  }

  ReactGA.initialize(envConfig.analyticsKey);
};

const searchClick = () => {
  ReactGA.event({
    category: "Search",
    action: "Click",
    label: "Search button",
  });
};

const voteUpClick = () => {
  ReactGA.event({
    category: "Vote",
    action: "Click",
    label: "Vote up button",
  });
};

const voteDownClick = () => {
  ReactGA.event({
    category: "Vote",
    action: "Click",
    label: "Vote down button",
  });
};

const reportViolationClick = () => {
  ReactGA.event({
    category: "Button",
    action: "Click",
    label: "Report violation button",
  });
};

const seeAllClick = () => {
  ReactGA.event({
    category: "Button",
    action: "Click",
    label: "See all button",
  });
};

const searchQueryInput = (value: string) => {
  const pattern = /^[A-Za-z]{3}-\d-[A-Za-z]{3}$/;

  if (pattern.test(value)) {
    ReactGA.set({
      dimension1: value,
    });

    ReactGA.event({
      category: "Search",
      action: "Query Input",
      label: "Search query input",
    });
  }
};

const analytics = {
  initialize,
  searchClick,
  voteUpClick,
  voteDownClick,
  reportViolationClick,
  seeAllClick,
  searchQueryInput,
};

export default analytics;
