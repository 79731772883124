import { FC, ReactNode } from "react";

import styles from "./Typography.module.scss";

interface TypographyProps {
  bold?: boolean;
  className?: string;
  children: ReactNode;
}

const Typography: FC<TypographyProps> = ({ bold, className, children }) => {
  const styling = `${styles.text} ${bold && styles.bold} ${className}`;

  return <div className={styling}>{children}</div>;
};

export default Typography;
